var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-row',{staticClass:"bgW"},[_c('el-col',{attrs:{"span":20}},[_c('el-form',{staticClass:"search",attrs:{"inline":true,"label-suffix":"：","size":"mini","model":_vm.search}},[_c('el-form-item',{attrs:{"label":"客户类型"}},[_c('el-select',{staticStyle:{"margin-left":"20px"},attrs:{"placeholder":"请选择"},on:{"change":_vm.levelChange},model:{value:(_vm.search.level),callback:function ($$v) {_vm.$set(_vm.search, "level", $$v)},expression:"search.level"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1)],1),_c('el-col',{class:_vm.admin_level == 1 ? 'btnBox1' : 'btnBox',attrs:{"span":4}},[_c('el-button',{staticClass:"mb10",attrs:{"type":"primary","size":"mini","loading":_vm.exportS},on:{"click":_vm.exportExcel}},[_vm._v("导出数据")])],1)],1),_c('el-row',{staticStyle:{"margin-bottom":"20px"}},[_c('el-alert',{attrs:{"type":"info","title":"ABCD客数量:门店成交的所有新单客户（客户首单金额大于等于500）滚动一年范围内在门店和门诊累计消费金额大于等于50000\\15000小于50000\\5000小于15000\\500小于5000。","closable":false}}),_c('el-alert',{attrs:{"type":"info","title":"ABCD客保有:A\\B\\C\\D客数量在最近三个月内门店/门诊有消耗或消费任何一个即为保有。","closable":false}})],1),_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.list,"border":"","size":"small","span-method":_vm.objectSpanMethod,"cell-style":_vm.cellStyle,"header-cell-style":_vm.$style.rowClass}},[_c('ElTableColumn',{attrs:{"label":"市场名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(row.market_name)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"门店名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"hand",on:{"click":function($event){return _vm.clickShop(row)}}},[_vm._v(" "+_vm._s(row.shop_name == '合计' ? '' : row.shop_name)+" ")])]}}])}),_c('ElTableColumn',{attrs:{"label":"客户保有量","prop":"all_baoyou"}}),_c('el-table-column',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.search.level)+"客数量 ")]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [(row.shop_name !== '合计')?_c('div',{staticClass:"hand",on:{"click":function($event){return _vm.clickBtn(row,row.total_count,'all')}}},[_vm._v(" "+_vm._s(row.total_count)+" ")]):_c('div',[_vm._v(" "+_vm._s(row.total_count)+" ")])]}}])}),_c('el-table-column',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.search.level)+"客保有数 ")]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [(row.shop_name !== '合计')?_c('div',{staticClass:"hand",on:{"click":function($event){return _vm.clickBtn(row,row.retained_count,'baoyou')}}},[_vm._v(" "+_vm._s(row.retained_count)+" ")]):_c('div',[_vm._v(" "+_vm._s(row.retained_count)+" ")])]}}])}),_c('el-table-column',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.search.level)+"客流失数 ")]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [(row.shop_name !== '合计')?_c('div',{staticClass:"hand",on:{"click":function($event){return _vm.clickBtn(row,row.lost_count,'liushi')}}},[_vm._v(" "+_vm._s(row.lost_count)+" ")]):_c('div',[_vm._v(" "+_vm._s(row.lost_count)+" ")])]}}])}),_c('el-table-column',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.search.level)+"客保有率 ")]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.retention_rate.replace('%',''))+"% ")]}}])}),_c('el-table-column',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.search.level)+"客流失预警数 ")]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [(row.shop_name !== '合计')?_c('div',{staticClass:"hand",on:{"click":function($event){return _vm.clickBtn(row,row.lost_warning,'yujing')}}},[_vm._v(" "+_vm._s(row.lost_warning)+" ")]):_c('div',[_vm._v(" "+_vm._s(row.lost_warning)+" ")])]}}])}),_c('el-table-column',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.search.level)+"客回流数 ")]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [(row.shop_name !== '合计')?_c('div',{staticClass:"hand",on:{"click":function($event){return _vm.clickBtn(row,row.reflux_count,'huiliu')}}},[_vm._v(" "+_vm._s(row.reflux_count)+" ")]):_c('div',[_vm._v(" "+_vm._s(row.reflux_count)+" ")])]}}])}),_c('el-table-column',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.search.level)+"预警回流率 ")]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.lost_warning_rate.replace('%',''))+"% ")]}}])}),_c('el-table-column',{attrs:{"prop":"year_amount"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.search.level)+"客年度消费值 ")]},proxy:true}])}),_c('el-table-column',{attrs:{"prop":"mz_amount"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.search.level)+"客门诊消费 ")]},proxy:true}])}),_c('el-table-column',{attrs:{"prop":"md_amount"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.search.level)+"客门店消费 ")]},proxy:true}])}),_c('el-table-column',{attrs:{"prop":"gongxian"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.search.level)+"客门店贡献值 ")]},proxy:true}])})],1),(_vm.dialogVisible)?_c('el-dialog',{attrs:{"title":"详细信息","visible":_vm.dialogVisible,"width":"70%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticStyle:{"margin":"0 auto","width":"90%"}},[_c('shop-table',{attrs:{"shopData":_vm.shopList}})],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("关 闭")])],1)]):_vm._e(),(_vm.admin_level == 1)?_c('shop-table',{staticStyle:{"margin-top":"20px"},attrs:{"shopData":_vm.shopList}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }